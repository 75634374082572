'use client';
import { setSentryAppVersion } from '@/configs/sentry';
import { useEffect } from 'react';

export default function ClientInitializations() {
  useEffect(() => {
    setSentryAppVersion();
  }, []);
  return null;
}
