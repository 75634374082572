import { useEffect, useRef, useState } from 'react';

export const useConnStatus = () => {
  //as user can't access this site/pages without internet, so keeping true initially
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    // Object.defineProperty(window.navigator, 'onLine', {
    //   get: function () {
    //     return onlineRef.current;
    //   },
    //   configurable: true,
    // });

    function connEventListener(e) {
      const isOnline = e.type === 'online';
      setIsOnline(isOnline);
    }

    window.addEventListener('online', connEventListener);
    window.addEventListener('offline', connEventListener);

    return () => {
      window.removeEventListener('online', connEventListener);
      window.removeEventListener('offline', connEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isOnline;
};
