// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = getFirebaseConfig();

function getFirebaseConfig() {
  let config = {};
  try {
    config = JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG);
  } catch (e) {
    console.error(e);
  }
  return config;
}

//Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
