'use client';
import React, { useEffect, useRef, useState } from 'react';
import OfflineSVG from 'public/assets/images/offline.svg';
import Dialog from '@/components/ui/Dialog';
import { useConnStatus } from '@/hooks/useConnStatus';
import { AndroidService } from '@/service/Android';
import { NO_INTERNET } from '@/constants/logs';
import { toast } from '@/utils';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

export default function OfflineDialog() {
  const isOnline = useConnStatus();
  const isBackPressed = useRef(false);

  useEffect(() => {
    if (!isOnline) {
      AndroidService.logEvent(NO_INTERNET, null);
      window.onSystemBack = () => {
        if (!isBackPressed.current) {
          isBackPressed.current = true;
          toast('Press back again to exit');
          setTimeout(() => {
            isBackPressed.current = false;
          }, 2000);
        } else {
          AndroidService.closeApp();
        }
      };
    } else {
      window.onSystemBack = null;
      isBackPressed.current = false;
    }
    return () => {
      window.onSystemBack = null;
      isBackPressed.current = false;
    };
  }, [isOnline, isBackPressed]);

  const CONTENT =
    LANGUAGES_CONTENT[StorageService.getLanguage()]['dialog'].offline;

  return (
    <Dialog
      isOpen={!isOnline}
      closable={false}
      noOutsideClickClose
      className="z-10 max-w-sm"
    >
      <div className="flex flex-col items-center justify-center ">
        <div className="flex items-center justify-center max-w-sm">
          <OfflineSVG className="w-48 max-w-screen-sm landscape:max-h-[30vh]" />
        </div>
        <h2 className="mb-2 font-semibold text-center text-color text-dimmed font-lg/loose">
          {CONTENT.title}
        </h2>
        <div className="flex gap-2">
          <div className="text-center text-sm leading-[24px] text-color font-normal text-dimmed/60">
            {CONTENT.description}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
