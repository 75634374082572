import * as Sentry from '@sentry/nextjs';
import { isLocalhost } from '../config';
import { AndroidService } from '@/service/Android';

const MockSentry = {
  init() {
    console.log('Sentry Init');
  },
  captureException(err) {
    console.log('Captured Err', err);
  },
  showReportDialog(data) {
    console.log('sentry: showReportDialog', data);
  },
  setExtra() {},
  withScope(cb) {
    console.log('Scope faked');
    cb({
      setExtras: error => {
        console.log(error);
      },
    });
  },
};

class GGSentry {
  constructor() {
    this.sentry = isLocalhost ? MockSentry : Sentry;
  }

  catchExceptions(err) {
    this.sentry.setExtra('error', err);
    this.sentry.captureException(err);
  }

  showReportDialog(data) {
    this.sentry.showReportDialog(data);
  }

  getInstance() {
    return this.sentry;
  }
}

export const ggSentry = new GGSentry();

export function setSentryAppVersion() {
  const appMeta = AndroidService.getAppMeta();
  Sentry.setTags({
    app_version: appMeta?.versionName,
    web_version: process.env.version,
  });
}
