'use client';
import HDialog from '../ui/Dialog';
import Button from '../ui/Button';
import { AndroidService } from '@/service/Android';
import { useEffect, useState, version } from 'react';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from 'firebase/remote-config';
import { firebaseApp } from '@/configs/firebase';

const title =
  LANGUAGES_CONTENT[StorageService.getLanguage()]['forceUpdate'].title;
const cta = LANGUAGES_CONTENT[StorageService.getLanguage()]['forceUpdate'].cta;

export default function ForceUpdateApp() {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 300000; // 5 minutes = 300,000 milliseconds

    remoteConfig.defaultConfig = {
      forceupdate: {
        version: -1,
        forceUpdate: false,
      },
    };
    fetchAndActivate(remoteConfig)
      .then(() => {
        try {
          const forceUpdateEnabled = getValue(
            remoteConfig,
            'forceUpdate'
          ).asString();

          const configObj = JSON.parse(forceUpdateEnabled);

          if (
            configObj.version > AndroidService.getAppMeta().versionNumber &&
            configObj.force_update
          ) {
            setOpenDialog(true);
          }
        } catch (err) {
          console.log('Error parsing remote config', err);
        }
      })
      .catch(err => {
        console.log('Error fetching remote config', err);
      });
  }, []);

  return (
    <HDialog
      isOpen={openDialog}
      onOpenChange={open => {
        setOpenDialog(false);
      }}
      // position="bottom"
      noOutsideClickClose
    >
      <div className="flex flex-col items-center">
        <h1 className="text-xl font-semibold mt-4">{title}</h1>
        <div className="flex justify-between items-center my-6 gap-4">
          <Button
            size="2"
            variant="solid"
            className="mr-3 border p-2 px-8 rounded"
            onClick={() => {
              AndroidService.openUrlInBrowser(
                `https://play.google.com/store/apps/details?id=${AndroidService.getPackageName()}`
              );
            }}
          >
            {cta}
          </Button>
        </div>
      </div>
    </HDialog>
  );
}
