import { useEffect, useRef, useState } from 'react';

export const useUserBanned = () => {
  const [isBanned, setIsBanned] = useState(() => {
    return false;
  });
  const bannedRef = useRef(isBanned);

  useEffect(() => {
    function banEventListener(e) {
      const isBan = e.type === 'banned';
      bannedRef.current = isBan;
      setIsBanned(isBan);
    }

    window.addEventListener('banned', banEventListener);

    return () => {
      window.removeEventListener('banned', banEventListener);
    };
  }, []);

  return isBanned;
};
