'use client';
import React, { useEffect } from 'react';
import VpnSVG from 'public/svgs/vpn.svg';
import Dialog from '@/components/ui/Dialog';
import { AndroidService } from '@/service/Android';
import { useUserBanned } from '@/hooks/useUserBanned';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

export default function ManualBanDialog() {
  const CONTENT = LANGUAGES_CONTENT[StorageService.getLanguage()].dialog.banned;
  const isBanned = useUserBanned();

  useEffect(() => {
    if (!isBanned) AndroidService.logEvent('user_banned_manually', null);
  }, [isBanned]);

  return (
    <Dialog
      isOpen={isBanned}
      closable={false}
      noOutsideClickClose
      className="z-10 max-w-sm"
    >
      <div className="flex flex-col items-center justify-center ">
        <div className="flex items-center justify-center max-w-sm">
          <VpnSVG className="w-72 max-w-screen-sm landscape:max-h-[30vh]" />
        </div>
        <h2 className="mb-2 font-semibold text-center text-color font-lg/loose">
          {CONTENT.heading}
        </h2>
        <div className="flex gap-2">
          <div className="text-center text-sm leading-[24px] font-normal text-black/60">
            {CONTENT.description} {process.env.NEXT_PUBLIC_SUPPORT_MAIL}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
